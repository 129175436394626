<template>
  <v-app>
    <v-card raised>
      <v-card-title>
        My appointments
        <v-spacer></v-spacer>

        <v-btn v-if="checkIsAccessible('appointment', 'create')"
               color="ma-2 btn btn-primary "
               small
               @click="addAppointment"
        >
          <v-icon x-small>
            fas fa-plus
          </v-icon>
          Add appointment
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <div class="row">
            <v-col cols="12" sm="12" md="2">
              <v-autocomplete
                  outlined
                  dense
                  :items="users"
                  item-text="full_name"
                  item-value="id"
                  v-model="search.user_id"
                  @input="search.user_id = $event !== null ? $event : ''"
                  clearable
                  label="Search by registered agent"
                  v-on:keyup.enter="getAppointments()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                  label="Search by candidate name, email or number"
                  outlined
                  dense
                  v-model="search.user_info"
                  @input="search.user_info = $event !== null ? $event : ''"
                  clearable
                  v-on:keyup.enter="getAppointments()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="2">
              <v-select
                  outlined
                  dense
                  @change="handleSearchDateTypeChange"
                  :items="searchDates"
                  item-text="date"
                  item-value="value"
                  v-model="search.search_date_type"
                  @input="search.search_date_type = $event !== null ? $event : ''"
                  clearable
                  label="Search"
                  v-on:keyup.enter="getAppointments()"
              ></v-select>
            </v-col>

            <v-col v-if="showDate" class="d-flex" cols="12" sm="12" md="3">
              <v-menu
                  ref="menu"
                  v-model="date"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="search.date"
                      label="Search By Date "
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      @input="search.date = $event !== null ? $event : ''"
                      clearable
                  >
                    <template v-slot:label>
                      Search By Date
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    v-model="search.date"
                    :active-picker.sync="activePicker"
                    min="1950-01-01"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="2">
              <v-btn
                  :loading="loading"
                  class="mt-1 btn btn-primary text-white"
                  @click="getAppointments"
              >
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </div>
        </div>
      </v-card-text>
      <!-- appointment listing -->
      <div>
        <v-card-subtitle>
          <div class="d-flex justify-content-between">
            <div>
              <v-btn
                  class="ma-2"
                  :class="search.type == 'all' ? 'active' : '' "
                  outlined
                  small
                  @click="setAppointmentType('all')"
              >
                <v-icon color="indigo" x-small>
                  fas fa-circle
                </v-icon>
                All
              </v-btn>

              <v-btn
                  class="ma-2"
                  :class="search.type == 'waiting' ? 'active' : ''"
                  outlined
                  small
                  @click="setAppointmentType('waiting')"
              >
                <v-icon color="pink" x-small>
                  fas fa-circle
                </v-icon>
                Waiting
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  small
                  :class="search.type == 'attended' ? 'active' : ''"
                  @click="setAppointmentType('attended')"
              >
                <v-icon color="green" x-small>
                  fas fa-circle
                </v-icon>
                Attended
              </v-btn>
              <v-btn
                  class="ma-2"
                  outlined
                  small
                  :class="search.type == 'missed' ? 'active' : ''"
                  @click="setAppointmentType('missed')"
              >
                <v-icon color="red" x-small>
                  fas fa-circle
                </v-icon>
                Missed
              </v-btn>

              <v-btn
                  class="ma-2"
                  outlined
                  small
                  :class="search.type == 'cancelled' ? 'active' : ''"
                  @click="setAppointmentType('cancelled')"
              >
                <v-icon color="orange" x-small>
                  fas fa-circle
                </v-icon>
                Cancelled
              </v-btn>
            </div>
            <div class="title">
              Total : {{ total }}
              <v-btn
                  class="ma-2"
                  outlined
                  small
                  color="black"
                  @click="setAppointmentType('all')"
              >
                <v-icon color="black" x-small>
                  fas fa-redo
                </v-icon>
                &nbsp; Reset Filter
              </v-btn>
            </div>
          </div>
        </v-card-subtitle>

        <v-card-text>
          <v-alert
              border="left"
              color="yellow accent-4"
              colored-border
              dense
              elevation="2"
              v-if="appointments.length != 0"
              v-for="(item, index) in appointments" :key="index"
          >
            <v-row>
              <v-col cols="2">
                <div class="d-flex flex-column flex align-items-center justify-content-center">
                  <div class="text-h2">
                    {{ item.formatted_date }}
                  </div>
                  {{ item.formatted_month }}
                </div>

              </v-col>
              <v-col cols="10">
                <div class="d-flex justify-content-around">
                  <!-- <div>
                      <v-icon :color="color">
                          fas fa-circle
                      </v-icon>
                      <span class="text-h6">
                      {{appointmentType}}
                      </span>
                  </div> -->
                  <div>
                    <v-icon color="primary">
                      fas fa-clock
                    </v-icon>
                    <span class="text-h6">
                                        {{ item.formatted_time }}
                                        </span>
                  </div>
                  <div>
                    <v-icon color="primary">
                      fas fa-user
                    </v-icon>
                    <span class="text-h6">
                                        {{ item.user_name }}
                                        </span>
                    <br><br>
                    <span v-if="! item.has_user_id && item.user_email">
                                            <i class="fa fa-envelope"></i> {{ item.user_email }}
                                        </span>
                    <br>
                    <span v-if="! item.has_user_id & item.user_email">
                                            <i class="fa fa-phone"></i> {{ item.user_phone }}
                                        </span>
                  </div>
                  <v-btn v-if="checkIsAccessible('appointment', 'edit')"
                         class="btn text-white btn-primary"
                         small
                         @click="changeStatus(item)"
                  >
                    Change Status
                  </v-btn>
                </div>
                <div class="border my-1 px-3 rounded">
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <p class="">
                    {{ item.note }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-alert>

          <b-pagination
              v-if="appointments.length > 0"
              class="pull-right mt-7"
              @input="getAppointments"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              :disabled="loading"
          ></b-pagination>

          <v-alert class="border" v-if="appointments.length == 0">
            <v-col cols="12">
              <div class="d-flex flex-column flex align-items-center justify-content-center">
                <v-col class="text-center md:items-center md:justify-between py-12">
                  <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
                  <p>No appointments available.</p>
                </v-col>
              </div>
            </v-col>
          </v-alert>
        </v-card-text>
      </div>
      <!-- appointment listing ends -->


      <CreateAndUpdateAppointment
          ref="create-and-update-appointment"
          @refresh="getAppointments"
      ></CreateAndUpdateAppointment>

      <UpdateAppointmentStatus
          ref="update-status"
          @refresh="getAppointments"
      ></UpdateAppointmentStatus>

    </v-card>
  </v-app>

</template>

<script>
import CreateAndUpdateAppointment from "./CreateAndUpdateAppointment.vue";
import AdminUserService from "@/services/admin-user/AdminUserService";
import UserService from "@/services/user/UserService";
import UpdateAppointmentStatus from "./UpdateAppointmentStatus";

const admin_user = new AdminUserService();
const user = new UserService();

export default {
  components: {
    CreateAndUpdateAppointment,
    UpdateAppointmentStatus
  },
  data() {
    return {
      appointments: [],
      date: '',
      search: {
        type: 'all',
        date: '',
        user_info: '',
        user_id: '',
        search_date_type: 'today'
      },
      showDate: false,
      searchDates: [
        {
          date: 'Yesterday',
          value: 'yesterday'
        },
        {
          date: 'Today',
          value: 'today'
        },
        {
          date: 'Tommorow',
          value: 'tommorow'
        },
        {
          date: 'Select Date',
          value: 'custom_date'
        },
      ],
      users: [],
      activePicker: null,
      loading: false,
      total: null,
      perPage: null,
      totalCounts: [],
      page: null,
    }
  },
  props: ['user'],
  methods: {
    getAppointments() {
      this.loading = true;
      admin_user
          .getAppointments(this.currentUser.id, this.search, this.page)
          .then((response) => {
            this.appointments = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          })
    },
    handleSearchDateTypeChange() {
      if (this.search.search_date_type == 'custom_date') {
        this.showDate = true;
      } else {
        this.showDate = false;
      }
    },
    changeStatus(appointment) {
      this.$refs['update-status'].updateStatus(appointment);
    },
    getAllUsers() {
      user
          .all()
          .then(response => {
            this.users = response.data.users;
          })
          .catch((err) => {
          });
    },
    getAppointmentsCount() {
      admin_user
          .getAppointmentsCount(this.currentUser.id)
          .then((response) => {
            this.totalCounts = response.data;
          });
    },
    setAppointmentType(type) {
      this.search.type = type;
      this.getAppointments();
    },

    addAppointment() {
      this.$refs['create-and-update-appointment'].createAndUpdateAppointment(this.currentUser.id);
    },
  },
  mounted() {
    this.getAppointments();
    this.getAllUsers();
  },
  computed: {
    appointmentType() {
      if (this.search.type == 'all') {
        return 'All'
      }
      if (this.search.type == 'attended') {
        return 'Attended'
      }
      if (this.search.type == 'missed') {
        return 'Missed'
      }
      if (this.search.type == 'waiting') {
        return 'Waiting'
      }
      if (this.search.type == 'cancelled') {
        return 'Cancelled'
      }
    },
    color() {
      if (this.search.type == 'all') {
        return 'indigo';
      }
      if (this.search.type == 'attended') {
        return 'green';
      }
      if (this.search.type == 'waiting') {
        return 'pink';
      }
      if (this.search.type == 'missed') {
        return 'red';
      }
      if (this.search.type == 'cancelled') {
        return 'gray';
      }
    },
  }
}
</script>

<style scoped>
.active {
  color: #fff;
  background: rgb(0, 0, 0) !important;
}
</style>
