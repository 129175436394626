<template>
    <v-dialog
            max-width="800"
            scrollable
            v-model="dialog"
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                    <span>Add appointment</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="">
                        <v-col cols="12" v-if="chosenDateFlag">
                            <div class="text-h6">Choose Date</div>
                        </v-col>

                        <v-col cols="12" md="12" v-if="chosenDateFlag">
                            <v-menu
                                    :close-on-content-click="false"
                                    min-width="auto"
                                    offset-y
                                    ref="menuDate"
                                    transition="scale-transition"
                                    v-model="menuStartDate"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            clearable
                                            dense
                                            label="Choose Date"
                                            outlined
                                            readonly
                                            v-bind="attrs"
                                            v-model="search.custom_date"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        @change="handleChoosenDateChange('custom')"
                                        @input="menuStartDate = false"
                                        dense
                                        no-title
                                        outlined
                                        v-model="search.custom_date"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" v-if="chosenTimeFlag">
                            <div class="text-h6 mt-2">Choose Time</div>
                        </v-col>

                        <v-col :key="index" cols="12" md="3" v-for="(item, index) in availableTimeSlots"
                               v-if="chosenTimeFlag">
                            <a
                                    :class="checkIfTimeSelected(item)"
                                    @click="setSelectedTime(item.is_booked, index, item.time)"
                                    class="date-wrapper"
                                    style="display: block;"
                                    v-if="availableTimeSlots && availableTimeSlots.length != 0"
                            >
                                <p v-if="! item.is_selected"> {{item.is_booked ? 'Booked' : 'Available'}} </p>
                                <p> {{item.is_selected ? 'Selected' : ''}} </p>
                                {{item.time}}
                            </a>
                        </v-col>

                        <v-col cols="12" v-if="chosenTimeFlag">
                            <div v-if="availableTimeSlots && availableTimeSlots.length == 0">No time slots appointed for
                                selected date
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="!allBooked && selectedField">
                            <label for="">Select From Student ? <span class="text-danger">*</span> </label>
                            <v-switch
                                @change="handleHasUserIdChange"
                                v-model="appointment.has_user_id"
                                :error="$v.appointment.has_user_id.$error"
                                :label="appointment.has_user_id ? 'Yes': 'No'"
                            ></v-switch>
                            <span class="text-danger" v-if="$v.appointment.title.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.has_user_id">{{ errors.has_user_id[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="12" v-if="!allBooked && selectedField && !showUserFields">
                            <v-autocomplete
                                    :items="users"
                                    dense
                                    item-text="full_name"
                                    item-value="id"
                                    :error="$v.appointment.taken_for_id.$error"
                                    label="Select Student"
                                    outlined
                                    v-model="appointment.taken_for_id"
                            >
                                <template v-slot:label>Select Student <span class="text-danger">*</span></template>
                            </v-autocomplete>
                            <span 
                                class="text-danger"
                                v-if="$v.appointment.taken_for_id.$error"
                            >
                                This Field is required
                            </span>
                            <span class="text-danger" v-if="errors.taken_for_id">{{ errors.taken_for_id[0] }}</span>
                        </v-col>

                        <v-col cols="12" v-if="!allBooked && selectedField && showUserFields">
                            <label for="">Student Name <span class="text-danger">*</span> </label>
                            <v-text-field 
                                outlined 
                                dense
                                :error="$v.appointment.user_name.$error"
                                v-model="appointment.user_name"
                            >
                                <template v-slot:label>
                                Student Name <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.appointment.user_name.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.user_name">{{ errors.user_name[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6" v-if="!allBooked && selectedField && showUserFields">
                            <label for="">Student Email </label>
                            <v-text-field 
                                outlined 
                                dense
                                placeholder="Student Email" 
                                :error="$v.appointment.user_email.$error"
                                v-model="appointment.user_email"
                            >
                            </v-text-field>
                            <span class="text-danger" v-if="$v.appointment.user_email.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.user_email">{{ errors.user_email[0] }}</span>
                        </v-col>

                        <v-col cols="12" md="6" v-if="!allBooked && selectedField && showUserFields">
                            <label for="">Student phone </label>
                            <v-text-field 
                                outlined 
                                dense
                                placeholder="Student phone" 
                                :error="$v.appointment.user_phone.$error"
                                v-model="appointment.user_phone"
                            >
                            </v-text-field>
                            <span class="text-danger" v-if="$v.appointment.user_phone.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.user_phone">{{ errors.user_phone[0] }}</span>
                        </v-col>

                        <v-col cols="12" v-if="!allBooked && selectedField">
                            <label for="">Title <span class="text-danger">*</span> </label>
                            <v-text-field
                                    dense
                                    outlined
                                    v-model="appointment.title"
                                    :error="$v.appointment.title.$error"
                            >
                            </v-text-field>
                            <span class="text-danger" v-if="$v.appointment.title.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.title">{{ errors.title[0] }}</span>
                        </v-col>

                        <v-col cols="12" v-if="!allBooked &&selectedField">
                            <label for="">Add a note <span class="text-danger"></span> </label>
                            <v-textarea
                                    dense
                                    label="Notes"
                                    outlined
                                    v-model="appointment.note"
                            >
                            </v-textarea>
                            <span class="text-danger" v-if="$v.appointment.note.$error">This Field is required</span>
                            <span class="text-danger" v-if="errors.note">{{ errors.note[0] }}</span>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        @click="closeDialog"
                        color="mr-2" text dark
                        medium
                >
                    Cancel
                </v-btn>
                <v-btn
                        :loading="loading"
                        @click="saveAppointment"
                        class="btn btn-primary"
                        medium
                        v-if="availableTimeSlots && availableTimeSlots.length != 0"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {required, requiredIf} from "vuelidate/lib/validators";
    import AdminUserService from "@/services/admin-user/AdminUserService";
    import AppointmentService from "@/services/appointment/AppointmentService";
    import AdminUserWorkingHourService from "@/services/admin-user/working-hour/AdminUserWorkingHourService";
    import UserService from "@/services/user/UserService";

    const admin_user = new AdminUserService();
    const appointment = new AppointmentService();
    const user_working_hour = new AdminUserWorkingHourService();
    const user = new UserService();

    export default {
        validations: {
            appointment: {
                taken_for_id: {
                    required: requiredIf(function (nestedModel){
                        return (this.appointment.has_user_id);
                    })
                },
                title: {required},
                description: {},
                date: {required},
                time: {required},
                note: {},
                type: {},
                status: {},
                has_user_id:{},
                user_name:{
                    required: requiredIf(function (nestedModel){
                        if(this.appointment.has_user_id){
                            return false;
                        }
                        return true;
                    })
                },
                user_email: {
                    required: requiredIf(function (nestedModel){
                        if(this.appointment.has_user_id){
                            return false;
                        }
                        if(this.appointment.user_phone){
                            return false;
                        }
                        return true;
                    })
                },
                user_phone: {
                    required: requiredIf(function (nestedModel){
                        if(this.appointment.has_user_id){
                            return false;
                        }
                        if(this.appointment.user_email){
                            return false;
                        }
                        return true;
                    })
                }
            }
        },
        data() {
            return {
                loading: false,
                dialog: false,
                admin_users: [],
                search: {
                    type: '',
                    custom_date: ''
                },
                users: [],
                appointment: {
                    taking_by_id: null,
                    taken_for_id: null,
                    title: '',
                    description: '',
                    date: '',
                    time: '',
                    note: '',
                    type: 'appointed',
                    status: 'scheduled',
                    has_user_id: 1,
                    user_name: '',
                    user_email: '',
                    user_phone: ''
                },
                showOtherFields: false,
                menuStartDate: false,
                chosenDateFlag: false,
                chosenTimeFlag: false,

                allBooked:true,
                selectedField:false,
                availableTimeSlots: [],
                showUserFields: false,
                errors: [],
            }
        },
        methods: {
            createAndUpdateAppointment(user_id) {
                this.resetForm();
                this.dialog = true;
                this.appointment.taking_by_id = user_id;
                this.handleAdminUserChange();
                this.getAllUsers();
            },
            checkIfTimeSelected(item) {
                if (item.is_booked) {
                    return 'booked';
                }
                if (item.is_selected) {
                    return 'active';
                }
            },
            checkSelected() {
                if (this.availableTimeSlots.length){
                    const selected = (element) => element.is_selected === true;
                    this.selectedField =this.availableTimeSlots.some(selected);
                }
            },
            checkShowOtherFields() {
                if (this.availableTimeSlots.length){
                    const booked = (element) => element.is_booked === true;
                    this.allBooked =this.availableTimeSlots.every(booked);
                }
            },
            handleHasUserIdChange(){
                if(this.appointment.has_user_id){
                    this.showUserFields = false;
                }else{
                    this.showUserFields = true;
                }
            },
            handleAdminUserChange() {
                this.availableTimeSlots = [];
                this.search.custom_date = '';
                this.chosenDateFlag = false;
                this.chosenTimeFlag = false;
                this.showOtherFields = false;

                if (this.appointment.taking_by_id != null) {
                    this.chosenDateFlag = true;
                } else {
                    this.chosenDateFlag = false;
                }
            },
            handleChoosenDateChange(choosenDate) {
                this.availableTimeSlots = [];
                this.chosenTimeFlag = true;
                this.search.type = choosenDate;
                this.showOtherFields = false;

                user_working_hour
                    .getAvailableWorkingHours(this.appointment.taking_by_id, this.search)
                    .then((response) => {
                        if (response.data.availableTimeSlots.length != 0) {
                            this.availableTimeSlots = response.data.availableTimeSlots;
                        }
                    })
                    .catch((err) => {
                        this.availableTimeSlots = [];
                    });
            },
            setSelectedTime(isBooked, index, time) {
                if (isBooked) {
                    this.$snotify.error('This time slot is already booked.')
                } else {
                    this.availableTimeSlots.map((item) => {
                        item.is_selected = false;
                    });
                    // this.showOtherFields = true;
                    this.availableTimeSlots[index].is_selected = true;
                    this.appointment.time = time;
                }
                this.checkShowOtherFields();
                this.checkSelected();
            },
            saveAppointment() {
                this.appointment.date = this.search.custom_date;
                this.$v.$touch()
                if (this.$v.$error) {
                    setTimeout(() => {
                        this.$v.$reset()
                    }, 3000);
                } else {
                    this.appointment.date = this.search.custom_date;
                    this.loading = true;
                    appointment
                        .create(this.appointment)
                        .then((response) => {
                            this.closeDialog();
                            this.$snotify.success('Appointment Saved Successfully.');
                            this.$emit('refresh');
                        })
                        .catch((err) => {
                            this.errors = err.errors;
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }

            },
            getAllUsers(){
                user
                .all()
                .then(response => {
                    this.users = response.data.users;
                })
                .catch((err) => {
                });
            },
            closeDialog() {
                this.resetForm();
                this.dialog = false;
            },
            resetForm() {
                this.appointment = {
                    taking_by_id: null,
                    taken_for_id: null,
                    title: '',
                    description: '',
                    date: '',
                    time: '',
                    note: '',
                    type: 'appointed',
                    status: 'scheduled',
                    has_user_id: 1,
                    user_name: '',
                    user_email: '',
                    user_phone: ''
                };
                this.errors = [];
                this.availableTimeSlots = [];
                this.showOtherFields = false;
                this.menuStartDate = false;
                this.chosenDateFlag = false;
                this.chosenTimeFlag = false;
                this.allBooked = true;
                this.selectedField = false;
                this.showUserFields = false;
                this.$v.$reset();
            }
        },
    }
</script>


<style scoped>
    .date-wrapper {
        margin-right: 1em;
        color: rgb(39, 38, 38);
        padding: 2rem;
        height: 7.7rem;
        padding-left: 1.1rem;;
        padding-right: 1.1rem;;
        border-radius: 100%;
        font-size: 16px;
        width: 7.7rem;
        text-align: center;
        border: 1px solid rgb(175, 171, 171);
        background: rgb(175, 171, 171);
    }

    .date-wrapper:hover {
        border: 1px solid rgb(138, 133, 133);
        background: indigo;
        color: #fff;
    }

    .active {
        background: indigo;
        color: #fff;
    }

    .booked {
        background: rgb(172, 15, 15);
        color: #fff;
    }
</style>